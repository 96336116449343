//
// To make future updates easier consider overriding the global variables from _variables.bootstrap.scss and _variables.custom.scss for current demo in this file.
// Note that this file is included first and variables defined in _variables.bootstrap.scss and _variables.custom.scss
// are not accessible in this file but you can override any global variable as shown below:
//

// Theme colors
// Override primary color variants
$primary: #007669; // Bootstrap variable
$primary-hover: #00534a; // Custom variable
$primary-light: #e1f0ff; // Custom variable
$primary-inverse: #ffffff; // Custom variable

// Layout Theme Colors
$layout-themes: (
  dark: #007669,
  // Dark theme color
  light: #ffffff // Light theme color,,,,
) !default;

$success: #007669 !default; // Bootstrap variable
$success-hover: #007669 !default; // Custom variable
$success-light: #cce4e1 !default; // Custom variable

// Dark colors
$dark: #007669 !default;
$dark-75: #339187 !default; // Custom variable
$dark-65: #4d9f96 !default; // Custom variable
$dark-50: #80bbb4 !default; // Custom variable
$dark-25: #cce4e1 !default; // Custom variable

// Dark colors
$dark: $dark !default; // Bootstrap variable
$dark-hover: darken($dark, 3%) !default; // Custom variable
$dark-light: $dark-25 !default; // Custom variable
$dark-inverse: #ffffff !default; // Custom variable

.form-control{
  color: #3F4254;
}